import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import SEO from "../../components/Seo";
import CardContainer from "../../components/CardContainer";
import CategoryList from "../../components/CategoryList";
import { useAlternateLangs } from "../../components/Hreflangs";

const StyledMiddle = styled.div`
  display: flex;
  padding: 0vmin 1vmin;

  @media all and (max-width: 540px) {
    flex-direction: column;
    justify-content: center;
  }
`;

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/blog"
);
const Blog = function ({ data, location }) {
  if (!data) return <p>Zut! Quelque chose s'est mal passé. Aucune donnée trouvée!</p>;

  // créer une liste de catégories de blog uniques
  // d'abord en obtenant toutes les chaînes avec des catégories
  const allCategoryStrings = [];
  data.allMdx.edges.map((item, i) =>
    // en divisant et en rognant la chaîne
    item.node.frontmatter.tags?.split(",").map(category => {
      // en divisant à : pour séparer les chemins des tags
      category = category.split(":")[0];
      return allCategoryStrings.push(category.trim());
    })
  );
  // nouvelle liste de catégories uniques par ordre alphabétique
  const uniqueCategories = Array.from(new Set(allCategoryStrings)).sort();

  return (
    <Layout location={location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Blog sur le SEO et l'Analyse Web"
          lang="fr"
          description="Lisez sur l'optimisation des moteurs de recherche et l'analyse web!"
          alternateLangs={alternateLangs}
        />
        <MainContent maxWidth="1000">
          <StyledMiddle>
            <CardContainer data={data} />
            <CategoryList data={uniqueCategories} />
          </StyledMiddle>
        </MainContent>
      </React.Fragment>

    </Layout>
  );
};

export default React.memo(Blog);

export const pageQuery = graphql`
    query BlogPageFrenchPosts {
      allMdx(
        filter: { fields: { collection: { eq: "post" } }, frontmatter: { language: { eq: "fr" } } }
        sort: { fields: [frontmatter___publish], order: DESC }
      ) {
        totalCount
        edges {
          node {
            id
            timeToRead
            excerpt
            fields {
              relativePath
              collection
              slug
            }
            frontmatter {
              title
              description
              publish(formatString: "DD. MMM YYYY")
              image {
                name
                relativePath
              }
              tags
              language
            }
          }
        }
      }
    }
  `;

